@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #74aaec;
  color: #fff;
  display: flex;
  font-family: 'Quicksand', sans-serif;
  height: 100%;
  line-height: initial;
  padding: 0 1.25rem;
  word-wrap: break-word;
}

.fade-in {
  animation: fade-in 2s linear;
}

.hidden {
  display: none;
}

.loader {
  animation: rotate 2s linear infinite;
  border: solid 0.625rem #fff;
  border-top: solid 0.625rem #9cc4ee;
  border-bottom: solid 0.625rem #9cc4ee;
  border-radius: 50%;
  height: 5.9375rem;
  margin: auto;
  width: 5.9375rem;
}

.error-message {
  margin: auto;
  text-align: center;
}

.weather-panel {
  margin: auto;
  max-width: 33.75rem;
  padding: 3.125rem 0;
  text-align: center;
  width: 100%;
}

.temperature-container {
  position: relative;
}

.temperature-container__icon {
  margin-right: 0.375rem;
}

.temperature-container__temperature {
  font-size: 4rem;
}

.temperature-container__unit {
  display: none;
}

.temperature-container__label {
  cursor: pointer;
  padding: 0.3125rem;
}

.temperature-container__label:hover,
.temperature-container__unit:checked+.temperature-container__label {
  border-bottom: solid 0.125rem #fff;
}

.weather-panel__city {
  display: block;
  font-size: 2rem;
  margin-top: 0.625rem;
}

.weather-panel__country {
  display: block;
  font-size: 0.875rem;
}

.weather-panel__current-condition {
  display: block;
  margin-top: 0.625rem;
}

.forecast-table {
  border-collapse: separate;
  border-spacing: 0.3125rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.625rem;
  max-width: 25rem;
  text-align: center;
  width: 100%;
}

.forecast-table__head {
  font-weight: bold;
}

.forecast-table__cell {
  vertical-align: middle;
}

.forecast-table__skycon {
  padding: 0.375rem;
}

.footer {
  font-size: 0.75rem;
  margin-top: 1rem;
  text-align: center;
}

.footer__separator::after {
  content: '|';
  margin: 0 0.5rem;
}

/*
 * Taken from https://github.com/tholman/github-corners
 */

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
  0%, 100% {
    transform:rotate(0);
  }
  20%,60% {
    transform:rotate(-25deg);
  }
  40%,80% {
    transform:rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }

  .github-corner:hover .octo-arm {
    animation:none;
  }
}
